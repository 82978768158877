import { SimpleTextModel } from './SimpleTextModel';

export class HeadlineModel extends SimpleTextModel {
    tag;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
            tag = 'h1',
        } = {}
    ) {
        super({ display, text, cssClasses });

        this.setTag(tag);
    }

    setTag(tag) {
        this.tag = tag;

        return this;
    }

    getTag() {
        return this.tag;
    }

    set tag(tag) {
        this.setTag(tag);
    }

    get tag() {
        return this.getTag();
    }
}
