import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de_DE from './de_DE';
import de_AT from './de_AT';
import de_CH from './de_CH';
import fr_CH from './fr_CH';
import nl_NL from './nl_NL';

Vue.use(VueI18n);

const messages = {
    'de-de': de_DE,
    'de-at': de_AT,
    'de-ch': de_CH,
    'fr-ch': fr_CH,
    'nl-nl': nl_NL,
};

export const i18n = new VueI18n({
    locale: 'de-de',
    fallbackLocale: 'de-de',
    messages
});
