<template>
    <component
        :is="component"
        :item="item"
    />
</template>

<script>
    import { HeadlineModel } from '../../models/HeadlineModel';
    import H1 from './Headline/H1';
    import H2 from './Headline/H2';

    export default {
        name: 'Headline',

        components: {
            H1,
            H2,
        },

        props: {
            item: {
                type: HeadlineModel,
                required: true,
            }
        },

        computed: {
            component() {
                return this.item.tag.toUpperCase();
            }
        }
    }
</script>
