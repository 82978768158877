<template>
    <b-row :class="Array.from(row.cssClasses).join(' ')">
        <b-col
            v-for="(column, cKey) in filteredColumns"
            v-bind="column.attributes"
            :key="cKey"
            :class="Array.from(column.cssClasses).join(' ')"
        >
            <component
                v-for="(item, iKey) in filterItems(column.items)"
                :key="iKey"
                :is="component(item)"
                :item="item"
            />
        </b-col>
    </b-row>
</template>

<script>
    import Headline from './Item/Headline';
    import ImageCard from './Item/ImageCard';
    import Group from './Item/Group';
    import SimpleButton from './Item/SimpleButton';
    import SimpleImage from './Item/SimpleImage';
    import SimpleText from './Item/SimpleText';
    import VideoCard from './Item/VideoCard';
    import { BCol, BRow } from 'bootstrap-vue';
    import { RowModel } from '../models/RowModel';
    import { VideoCardModel } from '../models/VideoCardModel';

    export default {
        name: 'Row',

        components: {
            BCol,
            BRow,
            Group,
            Headline,
            ImageCard,
            SimpleButton,
            SimpleImage,
            SimpleText,
            VideoCard,
        },

        props: {
            row: {
                type: RowModel,
                required: true,
            },
        },

        computed: {
            filteredColumns() {
                if (!this.row.columns) {
                    return [];
                }

                return Array.from(this.row.columns).filter(column => column.display);
            },
        },

        methods: {
            filterItems(items) {
                if (!items) {
                    return [];
                }

                return Array.from(items).filter(item => item.display);
            },

            component(component) {
                if (component instanceof VideoCardModel) {
                    return 'VideoCard';
                }

                let componentName = component.constructor.name;

                return componentName.substring(0, componentName.indexOf('Model'));
            },
        },
    }
</script>
