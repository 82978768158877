import { SimpleTextModel } from './SimpleTextModel';

export class SimpleImageModel extends SimpleTextModel {
    uri;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
            uri = '',
        } = {}
    ) {
        super({ display, text, cssClasses });

        this.setUri(uri);
    }

    setUri(uri) {
        this.uri = uri;

        return this;
    }

    getUri() {
        return this.uri;
    }

    set uri(uri) {
        this.setUri(uri);
    }

    get uri() {
        return this.getUri();
    }
}