<template>
    <div :class="Array.from(item.cssClasses).join(' ')">
        <component
            v-for="(groupItem, groupKey) in item.items"
            :key="groupKey"
            :is="component(groupItem)"
            :item="groupItem"
        />
    </div>
</template>

<script>
    import ImageButton from './ImageButton';
    import SimpleButton from './SimpleButton';
    import { GroupModel } from "../../models/GroupModel";

    export default {
        name: 'Group',

        components: {
            ImageButton,
            SimpleButton,
        },

        props: {
            item: {
                type: GroupModel,
                required: true,
            }
        },

        computed: {
            filteredItems() {
                if (!this.item.items) {
                    return [];
                }

                return Array.from(this.item.items).filter(item => item.display);
            }
        },

        methods: {
            component(component) {
                let componentName = component.constructor.name;

                return componentName.substring(0, componentName.indexOf('Model'));
            },
        },
    }
</script>
