import { CssClassesBasedModel } from './CssClassesBasedModel';

export class RowModel extends CssClassesBasedModel {
    columns;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            columns = new Set(),
        } = {}
    ) {
        super({ display, cssClasses });

        this.setColumns(columns);
    }

    setColumns(columns) {
        this.columns = columns;

        return this;
    }

    getColumns() {
        return this.columns;
    }

    set columns(columns) {
        this.setColumns(columns);
    }

    get columns() {
        return this.getColumns();
    }
}