<template>
    <div class="sblp-root">
        <b-container fluid="md" class="sblp-body is--cdn-promo-container">
            <row
                v-for="(row, key) in filteredRows"
                :key="key"
                :row="row"
            />

            <b-modal
                v-model="modal.show"
                :hide-header="true"
                @ok="modal.ok"
                @cancel="modal.cancel"
                centered
                static
            >
                {{ modal.message }}
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import Row from './components/Row';
import {BContainer} from 'bootstrap-vue';
import {BModal} from 'bootstrap-vue';
import {RowModel} from './models/RowModel';
import {ColumnModel} from './models/ColumnModel';
import {GroupModel} from './models/GroupModel';
// import {SimpleButtonModel} from './models/SimpleButtonModel';
import {SimpleTextModel} from './models/SimpleTextModel';
import {SimpleImageModel} from './models/SimpleImageModel';
import {HeadlineModel} from './models/HeadlineModel';
// import {ImageCardModel} from './models/ImageCardModel';
import {YoutubeVideoCardModel} from './models/YoutubeVideoCardModel';
// import { HTML5VideoCardModel } from './models/HTML5VideoCardModel';
import {ImageButtonModel} from "./models/ImageButtonModel";

export default {
    name: 'App',

    components: {
        BContainer,
        BModal,
        Row,
    },

    data() {
        const noop = () => {
        };

        return {
            rows: [
                /* NR. 1 */
                new RowModel({
                    cssClasses: new Set([
                        'position-relative',
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'position-relative',
                                'mb-5',
                            ]),
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t("base.hero"))
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'position-absolute',
                                'd-flex',
                                'justify-content-end',
                                'logo-col'
                            ]),
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t("base.logo")),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 2 */
                new RowModel({
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mb-md-0',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL('assets/img/34836_2008061551/Funktion543x300.jpg'),
                                    text: this.$t('realx.headline'),
                                }),
                            ]
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'text-center',
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                                'align-self': "center",
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('realx.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('realx.introtext1'),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('realx.introtext2'),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 3 */
                new RowModel({
                    cssClasses: new Set([
                        'bg-light'
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'text-center',
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                                'align-self': "center",
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('function.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([
                                        'text-center',
                                    ]),
                                    text: this.$t('function.introtext1'),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mb-md-0',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('function.image')),
                                    text: this.$t('function.headline'),
                                }),
                            ]
                        }),
                    ]),
                }),

                /* NR. 4 */
                new RowModel({
                    cssClasses: new Set([
                        'no-gutters',
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new YoutubeVideoCardModel({
                                    cssClasses: ['mb-2'],
                                    attributes: {
                                        /* Aspect ratio of the embed. Supported values are '16by9', '21by9', '4by3', and '1by1'. */
                                        aspect: "16by9",
                                        allowfullscreen: true,
                                    },
                                    uri: this.$t('rowthree.medialink1'),
                                    text: this.$t('rowthree.mediatext1'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new YoutubeVideoCardModel({
                                    cssClasses: ['mb-2'],
                                    attributes: {
                                        /* Aspect ratio of the embed. Supported values are '16by9', '21by9', '4by3', and '1by1'. */
                                        aspect: "16by9",
                                        allowfullscreen: true,
                                    },
                                    uri: this.$t('rowthree.medialink2'),
                                    text: this.$t('rowthree.mediatext2'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new YoutubeVideoCardModel({
                                    cssClasses: ['mb-2'],
                                    attributes: {
                                        /* Aspect ratio of the embed. Supported values are '16by9', '21by9', '4by3', and '1by1'. */
                                        aspect: "16by9",
                                        allowfullscreen: true,
                                    },
                                    uri: this.$t('rowthree.medialink3'),
                                    text: this.$t('rowthree.mediatext3'),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 4.1 */
                new RowModel({
                    cssClasses: new Set([
                        'bg-light'
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mb-md-0',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('furniture.image')),
                                    text: this.$t('furniture.headline'),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'text-center',
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                                'align-self': "center",
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('furniture.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([
                                        'text-center',
                                    ]),
                                    text: this.$t('furniture.introtext1'),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 5 */
                new RowModel({
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'mt-3',
                                'mt-md-5',
                                'mb-3',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 12,
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('sesselsofa.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('sesselsofa.introtext1'),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('sesselsofa.introtext2'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('sesselsofa.image1')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('sesselsofa.image2')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('sesselsofa.image3')),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 6 */
                new RowModel({
                    cssClasses: new Set([
                        'bg-light'
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            attributes: {
                                'cols': 12,
                                'md': 12,
                            },
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'mt-3',
                                'mt-md-5',
                                'mb-3',
                                'mx-auto',
                            ]),
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('support.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('support.introtext1'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('support.image1')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('support.image2')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('support.image3')),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 7 */
                new RowModel({
                    columns: new Set([
                        new ColumnModel({
                            attributes: {
                                'cols': 12,
                                'md': 12,
                            },
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'mt-3',
                                'mt-md-5',
                                'mb-3',
                                'mx-auto',
                            ]),
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('homeoffice.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('homeoffice.introtext1'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('homeoffice.image1')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('homeoffice.image2')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('homeoffice.image3')),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 8 */
                new RowModel({
                    cssClasses: new Set([
                        'bg-light'
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            attributes: {
                                'cols': 12,
                                'md': 12,
                            },
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'mt-3',
                                'mt-md-5',
                                'mb-3',
                                'mx-auto',
                            ]),
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('samscott.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([ 'text-center' ]),
                                    text: this.$t('samscott.introtext'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    cssClasses: [ 'mb-2' ],
                                    uri: this.$appConfig.withBaseURL(this.$t('samscott.image1')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    cssClasses: [ 'mb-2' ],
                                    uri: this.$appConfig.withBaseURL(this.$t('samscott.image2')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    cssClasses: [ 'mb-2' ],
                                    uri: this.$appConfig.withBaseURL(this.$t('samscott.image3')),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 9 */
                new RowModel({
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'mt-3',
                                'mt-md-5',
                                'mb-3',
                                'mx-auto',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 12,
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('table.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([ 'text-center' ]),
                                    text: this.$t('table.introtext'),
                                }),
                            ],
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    cssClasses: [ 'mb-2' ],
                                    uri: this.$appConfig.withBaseURL(this.$t('table.image1')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    cssClasses: [ 'mb-2' ],
                                    uri: this.$appConfig.withBaseURL(this.$t('table.image2')),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'mb-3',
                                'mb-md-5',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 4,
                            },
                            items: [
                                new SimpleImageModel({
                                    cssClasses: [ 'mb-2' ],
                                    uri: this.$appConfig.withBaseURL(this.$t('table.image3')),
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 10 */
                /*new RowModel({
                    // display: 'de-de' === this.$i18n.locale || 'de-ch' === this.$i18n.locale || 'fr-ch' === this.$i18n.locale || 'nl-nl' === this.$i18n.locale,
                    cssClasses: new Set([
                        'bg-light'
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'my-3',
                                'my-md-5',
                                'mx-auto',
                            ]),
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('favoriteplace.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([ 'text-center' ]),
                                    text: this.$t('favoriteplace.introtext'),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mx-auto',
                                'mb-md-0',
                                'mx-auto',
                                'text-center'
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('favoriteplace.image1')),
                                    text: this.$t('favoriteplace.headline'),
                                }),
                            ]
                        }),
                    ]),
                }),*/

                /* NR. 11 */
                new RowModel({
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mb-md-0',
                                'mx-auto',
                                'text-center',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('home.image')),
                                    text: this.$t('home.headline'),
                                }),
                            ]
                        }),

                        new ColumnModel({
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'my-3',
                                'my-md-5',
                                'mx-auto'
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                                'align-self': "center",
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('home.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set(['text-center']),
                                    text: this.$t('home.introtext'),
                                }),
                                new GroupModel({
                                    cssClasses: new Set([
                                        'app--button-group',
                                    ]),
                                    items: [
                                        new ImageButtonModel({
                                            text: this.$t('rowten.appbuttonapple'),
                                            uri: this.$t('rowten.applinkapple'),
                                            source: this.$appConfig.withBaseURL('assets/img/AppleApp.png'),
                                            attributes: {
                                                'width': 150,
                                            },
                                        }),
                                        new ImageButtonModel({
                                            text: this.$t('rowten.appbuttongoogle'),
                                            uri: this.$t('rowten.applinkgoogle'),
                                            source: this.$appConfig.withBaseURL('assets/img/GooglePlay.png'),
                                            attributes: {
                                                'width': 150,
                                            },
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    ]),
                }),

                /* NR. 12 */
                new RowModel({
                    cssClasses: new Set([
                        'bg-light'
                    ]),
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('warranty.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([
                                        'text-center',
                                    ]),
                                    text: this.$t('warranty.introtext'),
                                }),
                            ],
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mx-auto',
                                'mb-md-0',
                                'mx-auto',
                                'text-center'
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('warranty.image')),
                                    text: this.$t('warranty.headline'),
                                }),
                            ]
                        }),
                    ]),
                }),

                /* NR. 13 */

                /* NR. 14 */
                new RowModel({
                    columns: new Set([
                        new ColumnModel({
                            cssClasses: new Set([
                                'my-3',
                                'my-md-5',
                                'mx-auto',
                                'mb-md-0',
                                'mx-auto',
                                'text-center'
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new SimpleImageModel({
                                    uri: this.$appConfig.withBaseURL(this.$t('nature.image')),
                                    text: this.$t('nature.headline'),
                                }),
                            ]
                        }),
                        new ColumnModel({
                            cssClasses: new Set([
                                'align-self-center',
                                'text-center',
                                'my-3',
                                'my-md-5',
                            ]),
                            attributes: {
                                'cols': 12,
                                'md': 6,
                            },
                            items: [
                                new HeadlineModel({
                                    tag: 'h2',
                                    text: this.$t('nature.headline'),
                                    cssClasses: new Set([
                                        'is-smaller',
                                        'is-bold',
                                        'text-center',
                                        'mb-4',
                                    ]),
                                }),
                                new SimpleTextModel({
                                    cssClasses: new Set([
                                        'text-center',
                                    ]),
                                    text: this.$t('nature.introtext'),
                                }),
                            ],
                        }),
                    ]),
                }),
            ],

            modal: {
                show: false,
                message: '',
                ok: noop,
                cancel: noop,
            },
        };
    },

    computed: {
        filteredRows() {
            if (!this.rows) {
                return [];
            }

            return Array.from(this.rows).filter(row => row.display);
        }
    },

    methods: {
        confirmLeaving(href) {
            const url = new URL(href);

            this.modal.message = this.$i18n.t('confirm.external');

            if ('itunes.apple.com' === url.hostname) {
                this.modal.message = this.$i18n.t('confirm.externalApple');
            }

            if ('play.google.com' === url.hostname) {
                this.modal.message = this.$i18n.t('confirm.externalGoogle');
            }

            if ('www.stressless.com' === url.hostname) {
                this.modal.message = this.$i18n.t('confirm.internal');
            }

            this.modal.ok = () => {
                this.ok(href);
            };
            this.modal.show = true;
        },

        ok(location) {
            window.open(location, '_blank');
        },

        cancel() {
            const noop = () => {
            };

            this.modal.ok = noop;
            this.modal.cancel = noop;
        },
    },

    mounted() {
        document.querySelectorAll('.sblp-root a').forEach(el => {
            const href = el.getAttribute('href');

            if (!(href.startsWith('http://') || href.startsWith('https://'))) {
                return;
            }

            el.addEventListener('click', event => {
                event.preventDefault();
                this.confirmLeaving(href);
            });
        });
    },
};
</script>

<style lang="scss">
@import "src/styles/reboot";

.sblp-root {
    @import "~bootstrap/scss/bootstrap";
    @import "~bootstrap-vue/src/index";
    @import "src/styles/variables";

    /* Helper */
    /*.row {
        border-bottom: 1px solid red !important;
    }*/

    h1, h2, h3, h4, h5, h6,
    p {
        font-family: $font-family-base !important;
        color: #242429 !important;
    }

    h2.is-smaller { font-size: 1.5rem; }
    h2.is-bold { font-family: $font-family-base-bold !important; }

    .logo-col {
        img {
            max-width: 80px;
            margin: 5px;

            @include media-breakpoint-up(sm) {
                max-width: 100px;
                margin: 20px;
            }
        }
    }

    .fade {
        &:not(.show) {
            opacity: 0 !important;
        }

        &.show {
            opacity: 1 !important;
        }
    }

    .embed-responsive {
        height: 100%;
    }

    .is--cdn-promo-container {
        font-family: 'Lato', sans-serif !important;
        color: $secondary !important;
        padding: 0 12px !important;
        overflow: hidden;

        .bg-light {
            background-color: #f5f5f5 !important;
        }

        a:not(.btn),
        a:not(.btn):hover {
            color: $primary;
            text-decoration: underline;
        }

        h1 {
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;

            font-size: 1.5rem !important;
            font-weight: 500 !important;
            text-shadow: 0 0 10px rgba(0, 0, 0, .9) !important;
            margin-top: 45px;

            @include media-breakpoint-up(sm) {
                font-size: 2rem !important;
                margin-top: 0;
            }

            @media (min-width: 768px) {
                font-size: 4rem !important;
            }
        }

        .card {
            border: none !important;
            border-radius: 0 !important;
            background-color: transparent !important;
        }

        .card-img,
        .card-img-top {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }

        .card-title {
            margin: 0 !important;
            font-size: 1rem !important;
            color: $secondary;
        }

        .btn-primary {
            background-color: $primary;
            border-color: $primary;
            color: white;

            &:hover {
                background-color: $primary;
                border-color: $primary;
                color: white;
                opacity: 0.7;
            }
        }
    }

    .modal-dialog {
        .btn {
            border-radius: 0 !important;
        }

        .btn-primary {
            background-color: $primary !important;
            border-color: $primary !important;

            &:hover {
                background-color: $primary !important;
                border-color: $primary !important;
                opacity: 0.7 !important;
            }
        }

        .btn-secondary {
            background-color: $secondary !important;
            border-color: $secondary !important;

            &:hover {
                background-color: $secondary !important;
                border-color: $secondary !important;
                opacity: 0.7 !important;
            }
        }

        .modal-content {
            font-family: 'Lato', sans-serif !important;
            border-radius: 0 !important;
        }
    }
}
</style>
