import { SimpleImageModel } from './SimpleImageModel';

export class ImageCardModel extends SimpleImageModel {
    link;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
            uri = '',
            link = '',
        } = {}
    ) {
        super({ display, text, uri, cssClasses });

        this.setLink(link);
    }

    setLink(link) {
        this.link = link;

        return this;
    }

    getLink() {
        return this.link;
    }

    set link(link) {
        this.setLink(link);
    }

    get link() {
        return this.getLink();
    }
}
