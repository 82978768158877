import { CssClassesBasedModel } from './CssClassesBasedModel';

export class SimpleTextModel extends CssClassesBasedModel {
    text;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
        } = {}
    ) {
        super({ display, cssClasses });

        this.setText(text);
    }

    setText(text) {
        this.text = text;

        return this;
    }

    getText() {
        return this.text;
    }

    set text(text) {
        this.setText(text);
    }

    get text() {
        return this.getText();
    }
}
