import { render, staticRenderFns } from "./ImageButton.vue?vue&type=template&id=22218070&scoped=true&"
import script from "./ImageButton.vue?vue&type=script&lang=js&"
export * from "./ImageButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22218070",
  null
  
)

export default component.exports