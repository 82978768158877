import Vue from 'vue';
import App from './App.vue';
import Router from "vue-router";
import { createAppConfig } from './models/AppConfigModel';
import { i18n } from './plugins/i18n';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(Router);

Vue.config.productionTip = false;

const resolveFallbackLocale = url => {
     const locale = url.pathname.substring(1).split('/')[0];

     if (locale.match(/[a-z]{2}-[a-z]{2}/)) {
         return locale;
     }

     return null;
};

const isDev  = process.env.NODE_ENV === 'development';
const url    = new URL(document.currentScript.src);
const locale = url.searchParams.get('l') || resolveFallbackLocale(url);

if (locale) {
    i18n.locale = locale;
}

const vueConfiguration = {
    i18n,
    render: h => h(App),
};

if (!locale) {
    vueConfiguration.router = new Router({
        mode: 'history',
        routes: [
            {
                path: '/',
                // redirect: '/' + i18n.fallbackLocale,
            }, {
                path: '/:lang',
                component: {
                    template: '<router-view></router-view>',
                },
                beforeEnter(to, from, next) {
                    const lang = to.params.lang;

                    if (!i18n.messages[lang]) {
                        return next(i18n.fallbackLocale);
                    }

                    if (i18n.locale !== lang) {
                        i18n.locale = lang;
                    }

                    return next();
                },
                children: [],
            },
        ],
    });
}

Vue.prototype.$appConfig = createAppConfig({
    baseURL: process.env[isDev ? 'VUE_APP_DEV_CDN_BASE_URL' : 'VUE_APP_PROD_CDN_BASE_URL'],
});

let appId = 'app';

if (!isDev) {
    appId = '_' + Math.random().toString(36).substr(2, 16);
    document.write('<div id="' + appId + '"></div>');
}

document.addEventListener('DOMContentLoaded', () => {
    new Vue(vueConfiguration).$mount('#' + appId);
});
