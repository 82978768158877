<template>
    <b-embed
        :src="item.uri"
        v-bind="item.attributes"
        type="iframe"
    />
</template>

<script>
    import { BEmbed } from 'bootstrap-vue';
    import { YoutubeVideoCardModel } from '../../../models/YoutubeVideoCardModel';

    export default {
        name: 'Youtube',

        components: {
            BEmbed,
        },

        props: {
            item: {
                type: YoutubeVideoCardModel,
                required: true,
            }
        },
    }
</script>