export default {
    base: {
        mainheadline: '<span>Stressless<span>®</span> – Sitzmöbel mit Relaxfunktion</span>',
    },
    rowone: {
        image:'assets/img/34836_2008061551/Entspannung543x300.jpg',
    },
    rowfour: {
        headline: 'In nur 8 Werktagen zum neuen Lieblingsplatz',
        introtext: 'Ab sofort sind ausgewählte Ausführungen der beliebtesten Stressless<span>®</span> Modelle bereits in wenigen Tagen erhältlich. So können Sie innerhalb von nur acht Werktagen den Stressless<span>®</span> Komfort bei Ihnen zu Hause genießen (Abweichende Lieferzeiten bei Werksferien sind möglich). Erfahren Sie mehr über das Stressless<span>®</span> Schnell-Lieferprogramm oder kommen Sie vorbei – wir beraten sie gerne.',
        buttonlink: 'https://www.stressless.com/de-at/promotion/lieferprogramm?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
    rownine: {
        imagelink1: 'http://stressless.ekornes.com/DE/stresslessat/#/',
        imagelink2: 'https://www.stressless.com/de-at/promotion?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagelink3: 'https://www.stressless.com/de-at/kundenservice/garantie?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
    sesselsofa: {
        introtext2: 'Lassen Sie sich vom aktuellen <a href="https://stressless.ekornes.com/DE/stresslessat/" target="_blank">Stressless<sup>®</sup> Katalog</a> inspirieren oder entdecken Sie die <a href="https://stressless.ekornes.com/DE/promoat/" target="_blank">aktuellen Angebote</a>.',
    },
}
