<template>
    <b-button
        variant="link"
        :href="item.uri"
    >
        <b-img
            :src="item.source"
            :alt="item.text"
            v-bind="item.attributes"
        />
    </b-button>
</template>

<script>
    import { BButton, BImg } from 'bootstrap-vue';
    import { ImageButtonModel } from '../../models/ImageButtonModel';

    export default {
        name: "ImageButton",

        components: {
            BButton,
            BImg,
        },

        props: {
            item: {
                type: ImageButtonModel,
                required: true,
            }
        }
    }
</script>

<style scoped>

</style>
