export default {
    base: {
        mainheadline: '<span>Stressless<span>®</span> – Sitzmöbel mit Relaxfunktion</span>'
    },

    function: {
        introtext1: 'Das Kennzeichen der Stressless<sup>®</sup> Möbel ist das integrierte Plus<sup>™</sup> System. So können Sie sich ganz entspannt zurücklehnen und die für Sie perfekte Sitzposition finden. Zusätzlich bieten einige Modelle mit dem BalanceAdapt<sup>™</sup> System eine sanfte Schwingfunktion. Bei ausgewählten Sessel- und Sofamodellen, wie z. B. beim Sofa Mary, steht Ihnen eine elektrisch ausfahrbare Fussstütze (Power<sup>™</sup>) als Option für noch mehr Komfort zur Verfügung.',
    },
    furniture: {
        introtext1: 'Testen Sie den unvergleichlichen Stressless<sup>®</sup> Sitzkomfort. Wir sind Ihr Ansprechpartner für das Stressless<sup>®</sup> Sortiment und beraten Sie umfangreich zu den verschiedenen Ausführungen und Grössen, aktuellen Aktionen sowie Farb- und Materialkombinationen, damit Sie Ihr ideales Stressless<sup>®</sup> Möbelstück finden. Kontaktieren Sie uns gerne, um einen persönlichen Termin zu vereinbaren.',
    },
    support: {
        introtext1: 'Stressless<sup>®</sup> Power, eine innovative Motorfunktion, bietet Ihnen noch mehr Luxus und ist sowohl für Stressless<sup>®</sup> Relaxsessel als auch Sofas erhältlich. Durch das Drücken von Sensortasten können Sie sowohl die Fussstütze als auch die Neigung der Rückenlehne individuell einstellen. Diese Kombination ermöglicht eine optimale Unterstützung Ihres Körpers für einmaligen Komfort.',
    },
    sesselsofa: {
        introtext1: 'Komfort hat bei Stressless<sup>®</sup> viele Gesichter: Relaxsessel und Sofas stehen in zahlreichen Modellen, Farben und Ausführungen zur Auswahl. Klassische Modelle mit Holzgestell finden Sie genauso im Sortiment wie moderne Stahlgestelle. Wählen Sie Ihren Lieblingsbezug aus einem grossen Angebot an Leder- und Stoff-Farben aus, um Ihren Stressless<sup>®</sup> Möbelstück ganz nach Ihren Wünschen zu gestalten. ',
        introtext2: 'Lassen Sie sich vom aktuellen <a href="http://stressless.ekornes.com/DE/stresslesschde/#/" target="_blank">Stressless<sup>®</sup> Katalog</a> inspirieren oder entdecken Sie die <a href="https://stressless.ekornes.com/DE/promochde/" target="_blank">aktuellen Angebote</a>.',
    },
    homeoffice: {
        introtext1: 'Ein gut eingerichteter Arbeitsplatz - egal ob Zuhause oder im Büro - unterstützt die Produktivität. Auf den Komfort von Stressless müssen Sie auch während der Arbeitszeit nicht verzichten. Viele Sesselmodelle sind mit dem Home Office Gestell erhältlich. Entdecken Sie ausserdem die Stressless Home Office Modelle im schlanken Design, die ebenfalls mit einer Relaxfunktion ausgestattet sind und so für Ihren individuellen Komfort während der Arbeitszeit sorgen.',
    },
    samscott: {
        introtext: 'Fussstütze, Rückenlehne und Kopfstütze lassen sich bei diesen beiden Sesselmodellen <a href="https://www.youtube-nocookie.com/embed/viaks7Z7E0M" target="_blank">Sam</a> und <a href="https://www.youtube-nocookie.com/embed/xzDwNlreAlc" target="_blank">Scott</a> einfach per Fernbedienung separat und vollkommen stufenlos verstellen. Wahlweise können beide Sessel mit einer integrierten Heizfunktion und optional zusätzlich mit einer leichten Massagefunktion ausgestattet werden. Testen Sie dieses neue Sitz- und Liegeerlebnis in unserer Ausstellung.',
    },
    table: {
        introtext: 'Testen Sie <a href="https://www.youtube-nocookie.com/embed/adM9-7p3Edw" target="_blank">Stressless<sup>®</sup> Dining</a> die ersten Esszimmerstühle von Stressless<sup>®</sup>. Das bewährte BalanceAdapt™ System sowie ein stufenlosen Gleitsystem bringen den beliebten Stressless<sup>®</sup> Komfort auch an Ihren Esstisch. Machen Sie es sich bequem und geniessen Sie Ihre Mahlzeiten mit Stressless<sup>®</sup> Dining in perfekter Balance.',
    },
    favoriteplace: {
        introtext: 'Ab sofort sind ausgewählte Ausführungen der beliebtesten Stressless<sup>®</sup> Modelle bereits in wenigen Tagen erhältlich. So können Sie innerhalb von wenigen Werktagen den Stressless<sup>®</sup> Komfort bei Ihnen zu Hause geniessen (Abweichende Lieferzeiten bei Werksferien sind möglich). Erfahren Sie mehr über das <a href="https://stressless.ekornes.com/DE/stresslessschnell-lieferprogramm-2020deb2chandel/" target="_blank">Stressless<sup>®</sup> Schnell-Lieferprogramm</a> oder kommen Sie vorbei – wir beraten sie gerne.',
    },


    rowone: {
        image:'assets/img/34836_2008061551/Entspannung543x300.jpg',
        headline: 'Zeit für Entspannung.',
        introtext: 'Erleben Sie perfekte Entspannung: Die norwegische Marke Stressless<sup>®</sup> bietet höchsten Sitzkomfort durch intelligente und vielseitige Funktionen im skandinavischen Design.',
    },
    rowtwo: {
        headline: 'Funktionen für Ihren Komfort',
        introtext1: 'Das Kennzeichen der Stressless<sup>®</sup> Möbel ist das integrierte Plus™ System. So können Sie sich ganz entspannt zurücklehnen und die für Sie perfekte Sitzposition finden. Zusätzlich bieten einige Modelle mit dem BalanceAdapt™ System eine sanfte Schwingfunktion. Bei ausgewählten Sessel- und Sofamodellen steht Ihnen mit dem Power™ eine elektrisch ausfahrbare Fuss-Stütze als Option für noch mehr Komfort zur Verfügung.',
        introtext2: 'Entdecken Sie die vielseitigen Relaxsessel, Sofas und Esszimmerstühle von Stressless<sup>®</sup> und überzeugen Sie sich selbst in unserer Ausstellung vom beliebten Stressless<sup>®</sup> Komfort.',
    },
    rowthree: {
        mediatext1: 'Plus™ System',
        medialink1: 'https://www.youtube-nocookie.com/embed/6PR12v_2MyY',
        mediatext2: 'BalanceAdapt™',
        medialink2: 'https://www.youtube-nocookie.com/embed/H_nbnDymdFk',
        mediatext3: 'Power™',
        medialink3: 'https://www.youtube-nocookie.com/embed/WGXScDzZ-ek',
    },
    rowfour: {
        headline: 'In nur 8 Werktagen zum neuen Lieblingsplatz',
        introtext: 'Ab sofort sind ausgewählte Ausführungen der beliebtesten Stressless<sup>®</sup> Modelle bereits in wenigen Tagen erhältlich. So können Sie innerhalb von nur acht Werktagen den Stressless<sup>®</sup> Komfort bei Ihnen zu Hause geniessen (Abweichende Lieferzeiten bei Werksferien sind möglich). Erfahren Sie mehr über das Stressless<sup>®</sup> Schnell-Lieferprogramm oder kommen Sie vorbei – wir beraten sie gerne.',
        buttontext: 'Jetzt online entdecken',
        buttonlink: 'https://www.stressless.com/de-ch/promotion/lieferprogramm?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
    rowfive: {
        image: 'assets/img/hero.jpg',
        headline: 'Finden Sie Ihr Stressless<sup>®</sup> Möbelstück',
        introtext: 'Testen Sie den unvergleichlichen Stressless<sup>®</sup> Sitzkomfort. Wir sind Ihr Ansprechpartner für das Stressless<sup>®</sup> Sortiment und beraten Sie umfangreich zu den verschiedenen Ausführungen und Grössen, aktuellen Aktionen sowie Farb- und Materialkombinationen, damit Sie Ihr ideales Stressless<sup>®</sup> Möbelstück finden. Kontaktieren Sie uns gerne, um einen persönlichen Termin zu vereinbaren.',
    },
    rowsix: {
        headline: 'Sessel & Sofas: Komfort im Wohnzimmer',
        introtext1: 'Komfort hat bei Stressless<sup>®</sup> viele Gesichter: Relaxsessel und Sofas stehen in zahlreichen Modellen, Farben und Ausführungen zur Auswahl. Klassische Modelle mit Holzgestell finden Sie genauso im Sortiment wie moderne Stahlgestelle. Egal ob Sie Bezüge in Leder oder Stoff bevorzugen, wählen Sie Ihren Lieblingsbezug aus einem breiten Farb- und Materialangebot aus.',
        introtext2: 'Gestalten Sie Ihren neuen Sessel oder Ihr neues Sofa ganz nach Ihren Wünschen und geniessen Sie den Komfort von Stressless<sup>®</sup>.',
    },
    rowseven: {
        headline: 'Erweiterte Komfortfunktionen: Stressless<sup>®</sup> Sam und Scott',
        introtext: 'Fuss-Stütze, Rückenlehne und Kopfstütze lassen sich bei diesen beiden Sesselmodellen einfach per Fernbedienung separat und vollkommen stufenlos verstellen. Wahlweise können beide Sessel mit einer integrierten Heizfunktion oder mit einer Heizfunktion kombiniert mit einer leichten Massage ausgestattet werden. Testen Sie dieses neue Sitz- und Liegeerlebnis demnächst in unserer Ausstellung.',
    },
    roweight: {
        headline: 'Stressless<sup>®</sup> bittet zu Tisch',
        introtext: 'Testen Sie Stressless<sup>®</sup> Dining - die ersten Esszimmerstühle von Stressless<sup>®</sup>. Das bewährte BalanceAdapt™ System sowie ein stufenlosen Gleitsystem bringen den beliebten Stressless<sup>®</sup> Komfort auch an Ihren Esstisch. Machen Sie es sich bequem und geniessen Sie Ihre Mahlzeiten mit Stressless<sup>®</sup> Dining in perfekter Balance.',
    },
    rowten: {
        headline: 'Jetzt testen: Stressless @home',
        introtext: 'Entdecken Sie das Stressless<sup>®</sup> Sortiment jetzt praktisch per App. So können Sie alle Modelle nach Wunsch konfigurieren und sich dank des Augmented Reality-Konfigurators Ihr neues Stressless<sup>®</sup>-Möbelstück bereits virtuell in Ihrem Zuhause ansehen. Die App erhalten Sie kostenlos im Apple App Store sowie im Google Play Store.',
        appbuttonapple: 'App Store',
        applinkapple: 'https://itunes.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1&mt=8',
        appbuttongoogle: 'Google Play',
        applinkgoogle: 'https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers',
    },
    rownine: {
        headline: 'Entdecken Sie das aktuelle Sortiment von Stressless<sup>®</sup>',
        imagetext1: 'Katalog',
        imagelink1: 'https://www.stressless.com/de-ch/kundenservice/katalog?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagetext2: 'Aktuelle Angebote',
        imagelink2: 'https://www.stressless.com/de-ch/promotion?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagetext3: 'Registrierung Garantie',
        imagelink3: 'https://www.stressless.com/de-ch/kundenservice/garantie?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
}
