<template>
    <b-embed
        :poster="item.uri"
        v-bind="item.attributes"
        type="video"
    >
        <source
            v-for="(uri, type) in item.sources"
            :key="uri"
            :src="uri"
            :type="type"
        >
    </b-embed>
</template>

<script>
    import { BEmbed } from 'bootstrap-vue';
    import { HTML5VideoCardModel } from '../../../models/HTML5VideoCardModel';

    export default {
        name: 'HTML5',

        components: {
            BEmbed,
        },

        props: {
            item: {
                type: HTML5VideoCardModel,
                required: true,
            }
        },
    }
</script>