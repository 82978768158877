import { render, staticRenderFns } from "./SimpleButton.vue?vue&type=template&id=15a8df56&scoped=true&"
import script from "./SimpleButton.vue?vue&type=script&lang=js&"
export * from "./SimpleButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a8df56",
  null
  
)

export default component.exports