export class CssClassesBasedModel {
    display;
    cssClasses;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
        } = {}
    ) {
        this.setDisplay(display);
        this.setCssClasses(cssClasses);
    }

    setDisplay(display) {
        this.display = !!display;

        return this;
    }

    getDisplay() {
        return this.display;
    }

    set display(display) {
        this.setDisplay(display);
    }

    get display() {
        return this.getDisplay();
    }

    setCssClasses(cssClasses) {
        this.cssClasses = cssClasses;

        return this;
    }

    getCssClasses() {
        return this.cssClasses;
    }

    set cssClasses(cssClasses) {
        this.setCssClasses(cssClasses);
    }

    get cssClasses() {
        return this.getCssClasses();
    }
}
