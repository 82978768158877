import { SimpleImageModel } from './SimpleImageModel';

export class SimpleButtonModel extends SimpleImageModel {
    attributes;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
            uri = '',
            attributes = {},
        } = {}
    ) {
        super({ display, text, cssClasses, uri });

        this.setAttributes(attributes);
    }

    setAttributes(attributes) {
        this.attributes = attributes;

        return this;
    }

    getAttributes() {
        return this.attributes;
    }

    set attributes(attributes) {
        this.setAttributes(attributes);
    }

    get attributes() {
        return this.getAttributes();
    }
}
