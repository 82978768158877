<template>
    <b-img :src="item.uri" fluid :alt="item.text" />
</template>

<script>
    import { BImg } from 'bootstrap-vue';
    import { SimpleImageModel } from '../../models/SimpleImageModel';

    export default {
        name: 'SimpleImage',

        components: {
            BImg,
        },

        props: {
            item: {
                type: SimpleImageModel,
                required: true,
            }
        }
    }
</script>