import trimEnd from 'lodash/trimEnd';

export class AppConfigModel {
    baseURL;

    constructor(
        {
            baseURL = '',
        } = {}
    ) {
        this.setBaseURL(baseURL);
    }

    setBaseURL(baseURL) {
        this.baseURL = trimEnd(baseURL, '/') + '/';

        return this;
    }

    getBaseURL() {
        return this.baseURL;
    }

    set baseURL(baseURL) {
        this.setBaseURL(baseURL);
    }

    get baseURL() {
        return this.getBaseURL();
    }

    withBaseURL() {
        return this.baseURL + Array.from(arguments).join('/');
    }
}

export function createAppConfig(appConfig) {
    return Object.freeze(new AppConfigModel(appConfig));
}