import { ImageCardModel } from './ImageCardModel';

export class VideoCardModel extends ImageCardModel {
    attributes;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
            uri = '',
            attributes = '',
        } = {}
    ) {
        super({ display, text, uri, cssClasses });

        this.setAttributes(attributes);
    }

    setAttributes(attributes) {
        this.attributes = attributes;

        return this;
    }

    getAttributes() {
        return this.attributes;
    }

    set attributes(attributes) {
        this.setAttributes(attributes);
    }

    get attributes() {
        return this.getAttributes();
    }
}