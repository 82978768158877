<template>
    <div class="card mb-2">
        <component :is="component(item)" :item="item" />

        <div class="card-body">
            <h4 class="card-title">{{ item.text }}</h4>
        </div>
    </div>
</template>

<script>
    import HTML5 from './Video/HTML5';
    import Youtube from './Video/Youtube';
    import { VideoCardModel } from '../../models/VideoCardModel';

    export default {
        name: 'VideoCard',

        components: {
            HTML5,
            Youtube,
        },

        props: {
            item: {
                type: VideoCardModel,
                required: true,
            }
        },

        methods: {
            component(component) {
                let componentName = component.constructor.name;

                return componentName.substring(0, componentName.indexOf('VideoCardModel'));
            },
        },
    }
</script>