export default {
    confirm: {
        external: 'Sie verlassen jetzt die Website.',
        externalApple: 'Sie werden zum Apple App Store weitergeleitet.',
        externalGoogle: 'Sie werden zum Google Play Store weitergeleitet.',
        internal: 'Sie werden jetzt zu www.stressless.com weitergeleitet.',
    },
    base: {
        mainheadline: 'Stressless® - 50 Jahre perfekter Komfort',
        hero: 'assets/img/41289_2212231505/hero.jpg',
        logo: 'assets/img/logo.png',
    },
    realx: {
        headline: 'Stressless<sup>®</sup> – Sitzmöbel mit Relaxfunktion',
        introtext1: 'Wir leben in einer Welt, die zunehmend von Stress geprägt ist und wir müssen darauf achten, dass wir uns ausreichend kostbare Zeit für die wirklich wichtigen Dinge im Leben reservieren. Seit über 50 Jahren hilft Stressless<sup>®</sup> Menschen weltweit dabei, dank intelligenter und vielseitiger Funktionen für mehr Entspannung, Komfort und Balance im Leben zu sorgen.',
        introtext2: 'Entdecken Sie die vielseitigen Relaxsessel, Sofas und Esszimmerstühle von Stressless<sup>®</sup> und überzeugen Sie sich selbst vom beliebten Stressless<sup>®</sup> Komfort.',
    },
    function: {
        headline: 'Funktionen für Ihren Komfort',
        introtext1: 'Das Kennzeichen der Stressless<sup>®</sup> Möbel ist das integrierte Plus<sup>™</sup> System. So können Sie sich ganz entspannt zurücklehnen und die für Sie perfekte Sitzposition finden. Zusätzlich bieten einige Modelle mit dem BalanceAdapt<sup>™</sup> System eine sanfte Schwingfunktion. Bei ausgewählten Sessel- und Sofamodellen, wie z. B. beim Sofa Mary, steht Ihnen eine elektrisch ausfahrbare Fußstütze (Power<sup>™</sup>) als Option für noch mehr Komfort zur Verfügung.',
        image: 'assets/img/36751_2102261046/Funktionen.jpg',
    },
    furniture: {
        headline: 'Finden Sie Ihr Stressless<sup>®</sup> Möbelstück',
        introtext1: 'Testen Sie den unvergleichlichen Stressless<sup>®</sup> Sitzkomfort. Wir sind Ihr Ansprechpartner für das Stressless<sup>®</sup> Sortiment und beraten Sie umfangreich zu den verschiedenen Ausführungen und Größen, aktuellen Aktionen sowie Farb- und Materialkombinationen, damit Sie Ihr ideales Stressless<sup>®</sup> Möbelstück finden. Kontaktieren Sie uns gerne, um einen persönlichen Termin zu vereinbaren.',
        image: 'assets/img/36751_2102261046/Moebelstueck.jpg',
    },
    sesselsofa: {
        headline: 'Sessel & Sofas: Komfort im Wohnzimmer',
        introtext1: 'Komfort hat bei Stressless<sup>®</sup> viele Gesichter: Relaxsessel und Sofas stehen in zahlreichen Modellen, Farben und Ausführungen zur Auswahl. Klassische Modelle mit Holzgestell finden Sie genauso im Sortiment wie moderne Stahlgestelle. Wählen Sie Ihren Lieblingsbezug aus einem großen Angebot an Leder- und Stoff-Farben aus, um Ihr Stressless<sup>®</sup> Möbelstück ganz nach Ihren Wünschen zu gestalten. ',
        introtext2: 'Lassen Sie sich vom aktuellen <a href="http://stressless.ekornes.com/DE/stresslessde/#/" target="_blank">Stressless<sup>®</sup> Katalog</a> inspirieren oder entdecken Sie die <a href="https://www.stressless.com/de-de/promotions/dede/lederzumstoffpreis" target="_blank">aktuellen Angebote</a>.',
        image1: 'assets/img/41289_2212231505/SesselSofas/SesselSofas1.jpg',
        image2: 'assets/img/41289_2212231505/SesselSofas/SesselSofas2.jpg',
        image3: 'assets/img/41289_2212231505/SesselSofas/SesselSofas3.jpg',
    },
    support: {
        headline: 'Perfekte Unterstützung – vom Kopf bis zu den Zehenspitzen',
        introtext1: 'Stressless<sup>®</sup> Power, eine innovative Motorfunktion, bietet Ihnen noch mehr Luxus und ist sowohl für Stressless<sup>®</sup> Relaxsessel als auch Sofas erhältlich. Durch das Drücken von Sensortasten können Sie sowohl die Fußstütze als auch die Neigung der Rückenlehne individuell einstellen. Diese Kombination ermöglicht eine optimale Unterstützung Ihres Körpers für einmaligen Komfort.',
        image1: 'assets/img/36751_2102261046/Unterstuetzung/Unterstuetzung1.jpg',
        image2: 'assets/img/36751_2102261046/Unterstuetzung/Unterstuetzung2.jpg',
        image3: 'assets/img/36751_2102261046/Unterstuetzung/Unterstuetzung3.jpg',
    },
    homeoffice: {
        headline: 'Komfort im Home Office',
        introtext1: 'Ein gut eingerichteter Arbeitsplatz - egal ob Zuhause oder im Büro - unterstützt die Produktivität. Auf den Komfort von Stressless müssen Sie auch während der Arbeitszeit nicht verzichten. Viele Sesselmodelle sind mit dem Home Office Gestell erhältlich. Entdecken Sie außerdem die Stressless Home Office Modelle im schlanken Design, die ebenfalls mit einer Relaxfunktion ausgestattet sind und so für Ihren individuellen Komfort während der Arbeitszeit sorgen.',
        image1: 'assets/img/41289_2212231505/HomeOffice/HomeOffice1.jpg',
        image2: 'assets/img/41289_2212231505/HomeOffice/HomeOffice2.jpg',
        image3: 'assets/img/41289_2212231505/HomeOffice/HomeOffice3.jpg',
    },
    samscott: {
        headline: 'Heizung & Massage: Stressless<sup>®</sup> Sam und Scott',
        introtext: 'Fußstütze, Rückenlehne und Kopfstütze lassen sich bei diesen beiden Sesselmodellen <a href="https://www.youtube-nocookie.com/embed/viaks7Z7E0M" target="_blank">Sam</a> und <a href="https://www.youtube-nocookie.com/embed/xzDwNlreAlc" target="_blank">Scott</a> einfach per Fernbedienung separat und vollkommen stufenlos verstellen. Wahlweise können beide Sessel mit einer integrierten Heizfunktion und optional zusätzlich mit einer leichten Massagefunktion ausgestattet werden. Testen Sie dieses neue Sitz- und Liegeerlebnis in unserer Ausstellung.',
        image1: 'assets/img/36751_2102261046/SamScott/SamScott1.jpg',
        image2: 'assets/img/36751_2102261046/SamScott/SamScott2.jpg',
        image3: 'assets/img/36751_2102261046/SamScott/SamScott3.jpg',
    },
    table: {
        headline: 'Stressless<sup>®</sup> bittet zu Tisch',
        introtext: 'Testen Sie <a href="https://www.youtube-nocookie.com/embed/adM9-7p3Edw" target="_blank">Stressless<sup>®</sup> Dining</a>: die ersten Esszimmerstühle von Stressless<sup>®</sup>. Das bewährte BalanceAdapt™ System sowie das stufenlose Gleitsystem bringen den beliebten Stressless<sup>®</sup> Komfort auch an Ihren Esstisch. Machen Sie es sich bequem und genießen Sie Ihre Mahlzeiten mit Stressless<sup>®</sup> Dining in perfekter Balance.',
        image1: 'assets/img/41289_2212231505/Tisch/Tisch1.jpg',
        image2: 'assets/img/41289_2212231505/Tisch/Tisch2.jpg',
        image3: 'assets/img/41289_2212231505/Tisch/Tisch3.jpg',
    },
    favoriteplace: {
        headline: 'In nur 8 Werktagen zum neuen Lieblingsplatz',
        introtext: 'Ab sofort sind ausgewählte Ausführungen der beliebtesten Stressless<sup>®</sup> Modelle bereits in wenigen Tagen erhältlich. So können Sie innerhalb von wenigen Werktagen den Stressless<sup>®</sup> Komfort bei Ihnen zu Hause genießen (Abweichende Lieferzeiten bei Werksferien sind möglich). Erfahren Sie mehr über das <a href="https://stressless.ekornes.com/DE/stresslessschnell-lieferprogramm-2020deb2chandel/" target="_blank">Stressless<sup>®</sup> Schnell-Lieferprogramm</a> oder kommen Sie vorbei – wir beraten Sie gerne.',
        image1: 'assets/img/41289_2212231505/Lieblingsplatz.jpg',
    },
    home: {
        headline: 'Jetzt testen: <br>Stressless @home',
        introtext: 'Entdecken Sie das Stressless<sup>®</sup> Sortiment jetzt praktisch per App. So können Sie alle Modelle nach Wunsch konfigurieren und sich dank des Augmented-Reality-Konfigurator Ihr neues Stressless<sup>®</sup> Möbelstück bereits virtuell in Ihrem Zuhause ansehen. Die App erhalten Sie kostenlos im <a href="https://apps.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1" target="_blank">App Store</a> sowie im <a href="https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers" target="_blank">Google Play Store</a>.',
        image: 'assets/img/36751_2102261046/Home.jpg',
    },
    nature: {
        headline: 'Stressless<sup>®</sup> – im Einklang mit der Natur',
        introtext: 'Neben dem einzigartigen Komfort ist vor allem die nachhaltige und umweltschonende Produktion im Stressless<sup>®</sup> Werk in Norwegen ein wichtiges Ziel. So werden Stressless<sup>®</sup> Relaxmöbel unter Einhaltung strenger Umweltkriterien nur aus hochwertigen Materialien und besten Leder- und Stoffqualitäten individuell nach Ihren Wünschen gefertigt.',
        image: 'assets/img/36751_2102261046/Natur.jpg',
    },
    handmade: {
        headline: '50 Jahre Handwerkskunst aus Norwegen',
        introtext: 'Die Heimat von Stressless liegt in einem kleinen Ort an der Westküste Norwegens. Inspiriert von der malerischen Landschaft hatte der Unternehmer Jens E. Ekornes die Idee, den optimalen Sitzkomfort und einen Ort für echte Erholung zu schaffen. Der von ihm entwickelte Stressless<sup>®</sup> Relaxsessel feiert aktuell sein 50. Jubiläum. Er steht für das perfekte Zusammenspiel aus skandinavischer Tradition, handwerklichem Können, technologischen Innovationen und der Leidenschaft für Möbel.',
        image: 'assets/img/36751_2102261046/Norwegen.jpg',
    },
    warranty: {
        headline: 'Die Garantie von Stressless<sup>®</sup>',
        introtext: 'Sichern Sie sich nach dem Kauf von Stressless<sup>®</sup> Neuware 10 Jahre Garantie auf die innere Mechanik oder auch 5 Jahre Garantie auf elektrische Teile. Registrieren Sie dafür innerhalb von 6 Monaten nach dem Kauf Ihr Stressless<sup>®</sup> Möbelstück auf stressless.com. Sprechen Sie uns gerne an für mehr Details oder erfahren Sie mehr zur Garantie auf stressless.com.',
        image: 'assets/img/36751_2102261046/Garantie/Garantie_DE.jpg',
    },

    rowone: {
        image:'assets/img/36075_2011251513/Entspannung543x300.jpg',
        headline: 'Zeit für Entspannung.',
        introtext: 'Erleben Sie perfekte Entspannung: Die norwegische Marke Stressless<sup>®</sup> bietet höchsten Sitzkomfort durch intelligente und vielseitige Funktionen im skandinavischen Design.',
    },
    rowtwo: {
        headline: 'Funktionen für Ihren Komfort',
        introtext1: 'Das Kennzeichen der Stressless<sup>®</sup> Möbel ist das integrierte Plus™ System. So können Sie sich ganz entspannt zurücklehnen und die für Sie perfekte Sitzposition finden. Zusätzlich bieten einige Modelle mit dem BalanceAdapt™ System eine sanfte Schwingfunktion. Bei ausgewählten Sessel- und Sofamodellen steht Ihnen mit dem Power™ eine elektrisch ausfahrbare Fußstütze als Option für noch mehr Komfort zur Verfügung.',
        introtext2: 'Entdecken Sie die vielseitigen Relaxsessel, Sofas und Esszimmerstühle von Stressless<sup>®</sup> und überzeugen Sie sich selbst in unserer Ausstellung vom beliebten Stressless<sup>®</sup> Komfort.',
    },
    rowthree: {
        mediatext1: 'Plus™ System',
        medialink1: 'https://www.youtube-nocookie.com/embed/6PR12v_2MyY',
        mediatext2: 'BalanceAdapt™',
        medialink2: 'https://www.youtube-nocookie.com/embed/H_nbnDymdFk',
        mediatext3: 'Power™',
        medialink3: 'https://www.youtube-nocookie.com/embed/WGXScDzZ-ek',
    },
    rowfour: {
        headline: 'Schnell-Lieferprogramm von Stressless<sup>®</sup>',
        introtext: 'In wenigen Tagen zum neuen Lieblingsplatz: Ausgewählte Ausführungen der Stressless<sup>®</sup> Modelle sind bereits innerhalb von acht Werktagen bei Ihnen zu Hause (Lieferzeiten während der Werksferien können abweichen). Erfahren Sie mehr zu den verfügbaren Modellen bei uns im Studio. ',
        buttontext: 'Jetzt online entdecken',
    },
    rowfive: {
        image: 'assets/img/36075_2011251513/Moebelstueck543x300.jpg',
        headline: 'Finden Sie Ihr Stressless<sup>®</sup> Möbelstück',
        introtext: 'Testen Sie den unvergleichlichen Stressless<sup>®</sup> Sitzkomfort. Wir sind Ihr Ansprechpartner für das Stressless<sup>®</sup> Sortiment und beraten Sie umfangreich zu den verschiedenen Ausführungen und Größen, aktuellen Aktionen sowie Farb- und Materialkombinationen, damit Sie Ihr ideales Stressless<sup>®</sup> Möbelstück finden. Kontaktieren Sie uns gerne, um einen persönlichen Termin zu vereinbaren.',
    },
    rowsix: {
        headline: 'Sessel & Sofas: Komfort im Wohnzimmer',
        introtext1: 'Komfort hat bei Stressless<sup>®</sup> viele Gesichter: Relaxsessel und Sofas stehen in zahlreichen Modellen, Farben und Ausführungen zur Auswahl. Klassische Modelle mit Holzgestell finden Sie genauso im Sortiment wie moderne Stahlgestelle. Egal ob Sie Bezüge in Leder oder Stoff bevorzugen, wählen Sie Ihren Lieblingsbezug aus einem breiten Farb- und Materialangebot aus.',
        introtext2: 'Gestalten Sie Ihren neuen Sessel oder Ihr neues Sofa ganz nach Ihren Wünschen und genießen Sie den Komfort von Stressless<sup>®</sup>.',
    },
    rowseven: {
        headline: 'Erweiterte Komfortfunktionen: Stressless<sup>®</sup> Sam und Scott',
        introtext: 'Fußstütze, Rückenlehne und Kopfstütze lassen sich bei diesen beiden Sesselmodellen einfach per Fernbedienung separat und vollkommen stufenlos verstellen. Wahlweise können beide Sessel mit einer integrierten Heizfunktion oder mit einer Heizfunktion kombiniert mit einer leichten Massage ausgestattet werden. Testen Sie dieses neue Sitz- und Liegeerlebnis demnächst in unserer Ausstellung.',
    },
    roweight: {
        headline: 'Stressless<sup>®</sup> bittet zu Tisch',
        introtext: 'Testen Sie Stressless<sup>®</sup> Dining - die ersten Esszimmerstühle von Stressless<sup>®</sup>. Das bewährte BalanceAdapt™ System sowie ein stufenlosen Gleitsystem bringen den beliebten Stressless<sup>®</sup> Komfort auch an Ihren Esstisch. Machen Sie es sich bequem und genießen Sie Ihre Mahlzeiten mit Stressless<sup>®</sup> Dining in perfekter Balance.',
    },
    rowten: {
        headline: 'Die App stressless @home',
        introtext: 'Entdecken Sie das Stressless<sup>®</sup> Sortiment jetzt praktisch per App. So können Sie alle Modelle nach Wunsch konfigurieren und sich dank des Augmented Reality-Konfigurators Ihr neues Stressless<sup>®</sup>-Möbelstück bereits virtuell in Ihrem Zuhause ansehen. Die App erhalten Sie kostenlos im Apple App Store sowie im Google Play Store.',
        appbuttonapple: 'App Store',
        applinkapple: 'https://itunes.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1&mt=8',
        appbuttongoogle: 'Google Play',
        applinkgoogle: 'https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers',
    },
    rownine: {
        headline: 'Entdecken Sie das aktuelle Sortiment von Stressless<sup>®</sup>',
        imagetext1: 'Katalog',
        imagelink1: 'http://stressless.ekornes.com/DE/stresslessde/#/?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagetext2: 'Aktueller Prospekt',
        imagelink2: 'http://stressless.ekornes.com/DE/promode?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
    roweleven: {
        headline: 'Stressless<sup>®</sup> Garantie: Jetzt registrieren',
        introtext: 'Sie haben ein neues Stressless<sup>®</sup> Möbelstück gekauft? Besuchen Sie stressless.com für die Garantieregistrierung innerhalb von sechs Monaten nach dem Kauf und erfahren Sie mehr zu den Details der Stressless® Garantie. Nur gültig für Neuware.',
    },
}
