export default {
    confirm: {
        external: 'Sie verlassen jetzt die Website.',
        externalApple: 'U wordt doorgestuurd naar de Apple App Store.',
        externalGoogle: 'U wordt doorgestuurd naar de Google Play Store.',
        internal: 'U wordt nu doorgestuurd naar www.stressless.com.',
    },
    base: {
        mainheadline: '<span>Stressless<span>®</span> – 50 jaar perfect comfort</span>',
    },
    realx: {
        headline: 'Stressless<sup>®</sup> – Zitmeubels met relaxfunctie',
        introtext1: 'We leven in een steeds stressvollere wereld en we moeten ervoor zorgen dat we voldoende kostbare tijd reserveren voor de echt belangrijke dingen in het leven. Al 50 jaar helpt Stressless<sup>®</sup> mensen over de hele wereld om meer ontspanning, comfort en evenwicht in hun leven te bereiken door intelligente en veelzijdige functies.',
        introtext2: 'Ontdek de veelzijdige relaxfauteuils, banken en eetkamerstoelen van Stressless<sup>®</sup> en overtuig uzelf van het populaire Stressless<sup>®</sup> comfort.',
    },
    function: {
        headline: 'Functies voor uw comfort',
        introtext1: 'Kenmerkend voor Stressless<sup>®</sup> meubels is het geïntegreerde Plus<sup>™</sup> systeem. U kunt volkomen ontspannen achteroverleunen en de zitpositie vinden die voor u het prettigst is. Bovendien hebben een paar modellen het BalanceAdapt<sup>™</sup> systeem een zachte swingfunctie. Bij bepaalde fauteuil- en bankmodellen, bijv. bij de bank Emily, is een elektrisch uitschuifbare voetensteun (Power<sup>™</sup>) als optie beschikbaar voor nog meer comfort.',
        image: 'assets/img/36751_2102261046/Funktionen.jpg',
    },
    furniture: {
        headline: 'Vind uw Stressless<sup>®</sup> meubel',
        introtext1: 'Test het onvergelijkbare Stressless<sup>®</sup> zitcomfort. Wij zijn uw contactpersoon voor het Stressless<sup>®</sup> assortiment en adviseren u graag over de verschillende uitvoeringen en maten, actuele acties en kleur- en materiaalcombinaties, zodat u uw ideale Stressless<sup>®</sup> meubel vindt. Neem a.u.b. contact met ons op om een persoonlijke afspraak te maken.',
    },
    sesselsofa: {
        headline: 'Fauteuils en banken: Comfort in de woonkamer',
        introtext1: 'Comfort heeft bij Stressless<sup>®</sup> vele gezichten: Relaxfauteuils en banken zijn verkrijgbaar in vele modellen, kleuren en uitvoeringen. Klassieke modellen met een houten onderstel vindt u net zo goed in het assortiment als moderne stalen onderstellen. Kies uw favoriete bekleding uit een ruim assortiment leer- en stofkleuren om uw Stressless<sup>®</sup> meubel helemaal naar uw smaak te maken.',
        introtext2: 'Laat u door de actuele <a href="http://stressless.ekornes.com/DE/stresslessnl/#/" target="_blank">Stressless<sup>®</sup> catalogus</a> inspireren of ontdek de actuele aanbiedingen.',
    },
    support: {
        headline: 'Perfecte ondersteuning - van top tot teen',
        introtext1: 'Stressless<sup>®</sup> Power, een innovatieve motorfunctie, biedt u nog meer luxe en is verkrijgbaar voor zowel Stressless<sup>®</sup> relaxfauteuils als zitbanken. Door sensortoetsen in te drukken kunt zowel de voetsteun als de hoek van de rugleuning individueel instellen. Deze combinatie zorgt voor een optimale ondersteuning van uw lichaam voor een ongeëvenaard comfort.',
    },
    homeoffice: {
        headline: 'Comfort in uw kantoor thuis',
        introtext1: 'Een ingerichte werkplek thuis of op kantoor ondersteunt de productiviteit. Ook onder werktijd hoeft u het comfort van Stressless niet te missen. Vele fauteuil modellen zijn verkrijgbaar met een Home Office onderstel. Ontdek ook de Stressless Home Office modellen in slank design, die ook zijn uitgerust met een relaxfunctie en zo uw individuele comfort tijdens werktijd garanderen.',
    },
    samscott: {
        headline: 'Verwarming en massage: Stressless<sup>®</sup> Sam en Scot',
        introtext: 'De voetsteun, rugleuning en hoofdsteun van deze twee fauteuil modellen <a href="https://www.youtube-nocookie.com/embed/rJqkmlvYhQ0" target="_blank">Sam</a> en <a href="https://www.youtube-nocookie.com/embed/oRM5qbA0AWI" target="_blank">Scott</a> kunnen afzonderlijk en volledig traploos met een afstandsbediening worden versteld. Naar keuze kunnen beide fauteuils worden uitgerust met een geïntegreerde verwarmingsfunctie en optioneel met een lichte massagefunctie. Test deze nieuwe zit- en ligervaring in onze showroom.',
    },
    table: {
        headline: 'Stressless<sup>®</sup> nodigt u uit aan tafel',
        introtext: 'Probeer <a href="https://www.youtube-nocookie.com/embed/s7DxqICYtOs" target="_blank">Stressless<sup>®</sup> Dining</a> de eerste eetkamerstoelen van Stressless<sup>®</sup> Het beproefde BalanceAdapt™ systeem en een traploos glijsysteem brengen het populaire Stressless<sup>®</sup> comfort ook aan uw eettafel. Maak het comfortabel voor uzelf en geniet in perfecte balans van uw maaltijden met Stressless<sup>®</sup> Dining.',
    },
    favoriteplace: {
        headline: 'Binnen slechts 8 werkdagen heeft u uw lievelingsplekje',
        introtext: 'Vanaf nu zijn geselecteerde versies van de populairste Stressless<sup>®</sup> modellen binnen enkele dagen beschikbaar. Dit betekent dat u binnen slechts een paar werkdagen kunt genieten van Stressless<sup>®</sup> comfort in uw eigen huis (levertijden kunnen variëren tijdens de fabrieksvakanties). Kom meer te weten over het <a href="https://stressless.ekornes.com/DE/stresslessschnell-lieferprogramm-2020nldealer/" target="_blank">Stressless<sup>®</sup> snelleveringsprogramma</a> of kom langs – wij adviseren u graag.',
    },
    home: {
        headline: 'Nu testen: <br>Stressless @home',
        introtext: 'Ontdek nu het Stressless<sup>®</sup> assortiment praktisch in de app. U kunt alle modellen naar wens configureren en dankzij de augmented reality configurator uw nieuwe Stressless<sup>®</sup> meubel al virtueel in uw huis bekijken. U kunt de app gratis downloaden in de <a href="https://itunes.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1&mt=8" target="_blank">Apple App Store</a> en in de <a href="https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers" target="_blank">Google Play Store</a>.',
    },
    warranty: {
        headline: 'De garantie van Stressless<sup>®</sup>',
        introtext: 'Als u een nieuwe Stressless<sup>®</sup> koopt, bent u verzekerd van 10 jaar garantie op het interne mechanisme of 5 jaar garantie op de elektrische onderdelen. Registreer uw Stressless<sup>®</sup> meubels binnen 6 maanden na aankoop op stressless.com. Neem gerust contact met ons op voor meer informatie of lees meer over de garantie op stressless.com.',
        image: 'assets/img/36751_2102261046/Garantie/Garantie_NL.jpg',
    },
    handmade: {
        headline: '50 jaar vakmanschap uit Noorwegen',
        introtext: 'Stressless komt oorspronkelijk uit een klein dorpje aan de westkust van Noorwegen. Geïnspireerd door het schilderachtige landschap kwam ondernemer Jens E. Ekornes op het idee om optimaal zitcomfort en een plek voor echte ontspanning te creëren. De door hem ontwikkelde Stressless<sup>®</sup> relaxfauteuil viert momenteel zijn 50e verjaardag. Het staat voor het perfecte samenspel van Scandinavische traditie, vakmanschap, technologische innovaties en een passie voor meubels.',
    },
    nature: {
        headline: 'Stressless<sup>®</sup> – in harmonie met de natuur',
        introtext: 'Naast het unieke comfort is vooral de duurzame en milieuvriendelijke productie in de fabriek van Stressless<sup>®</sup> in Noorwegen een belangrijke doelstelling. Zo worden Stressless<sup>®</sup> relaxmeubels alleen gemaakt van hoogwaardige materialen en de beste leer- en stofkwaliteiten terwijl de strengste milieu eisen in acht worden genomen en er tegelijkertijd rekening wordt gehouden met uw wensen.',
    },

    rowone: {
        image:'assets/img/34836_2008061551/Entspannung543x300.jpg',
        headline: 'Tijd voor ontspanning',
        introtext: 'De optimale ontspanning: Het Noorse merk Stressless<sup>®</sup> biedt optimaal zitcomfort door intelligente en veelzijdige functies in Scandinavisch design.',
    },
    rowtwo: {
        headline: 'Functies voor uw comfort',
        introtext1: 'Kenmerkend voor Stressless<sup>®</sup>-meubels is het geïntegreerde Plus™systeem. U kunt volkomen ontspannen achteroverleunen en de zitpositie vinden die voor u het prettigst is. Bovendien hebben een paar modellen met het BalanceAdapt™systeem een zachte swingfunctie. Bij bepaalde fauteuilen bankmodellen biedt Power™ u als optie een elektrisch uitschuifbare voetensteun voor nog meer comfort.',
        introtext2: 'Ontdek de veelzijdige relaxfauteuils, banken en eetkamerstoelen van Stressless<sup>®</sup> en overtuig uzelf van het populaire Stressless<sup>®</sup>-comfort.',
    },
    rowthree: {
        mediatext1: 'Plus™ System',
        medialink1: 'https://www.youtube-nocookie.com/embed/ECzCGMG7_jQ',
        mediatext2: 'BalanceAdapt™',
        medialink2: 'https://www.youtube-nocookie.com/embed/tehVG2VII9Y',
        mediatext3: 'Power™',
        medialink3: 'https://www.youtube-nocookie.com/embed/qfzCf9JxkAE',
    },
    rowfour: {
        headline: 'In slechts 8 werkdagen heeft u uw lievelingsplekje',
        introtext: 'Vanaf nu zijn geselecteerde versies van de meest populaire Stressless<sup>®</sup>-modellen binnen enkele dagen beschikbaar. Dit betekent dat u in slechts acht werkdagen kunt genieten van Stressless<sup>®</sup> comfort in uw eigen huis (levertijden kunnen variëren tijdens de fabrieksvakanties). Kom meer te weten over het  Stressless<sup>®</sup> snelleveringsprogramma of kom bij ons langs – wij adviseren u graag.',
        buttontext: 'Meer',
        buttonlink: 'https://www.stressless.com/nl-nl/promotie/snelleveringsprogramma?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
    rowfive: {
        image: 'assets/img/hero.jpg',
        headline: 'Vind uw Stressless<sup>®</sup>-meubel',
        introtext: 'Test het onvergelijkbare Stressless<sup>®</sup> zitcomfort. Wij zijn uw contactpersoon voor het Stressless<sup>®</sup> assortiment en adviseren u graag over de verschillende uitvoeringen en maten, actuele acties en kleur- en materiaalcombinaties, zodat u uw ideale Stressless<sup>®</sup> meubel vindt. Neem a.u.b. contact met ons op om een persoonlijke afspraak te maken.',
    },
    rowsix: {
        headline: 'Fauteuils en banken: Comfort in de woonkamer',
        introtext1: 'Comfort heeft bij Stressless<sup>®</sup> vele gezichten: Relaxfauteuils en banken zijn verkrijgbaar in vele modellen, kleuren en afwerkingen. Klassieke modellen met een houten onderstel vindt u net zo goed in het assortiment als moderne stalen onderstellen. Of u nu de voorkeur geeft aan een bekleding in leer of stof, kies uw favoriete bekleding uit een breed aanbod aan kleuren en materialen.',
        introtext2: 'Ontwerp uw nieuwe fauteuil of bank volgens uw wensen en geniet van het comfort van Stressless<sup>®</sup>.',
    },
    rowseven: {
        headline: 'Uitgebreide comfortfuncties: Stressless<sup>®</sup> Sam en Scott',
        introtext: 'De voetsteun, rugleuning en hoofdsteun van deze twee fauteuilmodellen kunnen afzonderlijk en volledig traploos met een afstandsbediening worden versteld. Naar keuze kunnen beide fauteuils worden uitgerust met een geïntegreerde verwarmingsfunctie of met een verwarmingsfunctie in combinatie met een lichte massage. Test deze nieuwe zit- en ligervaring binnenkort in onze showroom.',
    },
    roweight: {
        headline: 'Stressless<sup>®</sup> nodigt uit om aan tafel te gaan',
        introtext: 'Probeer Stressless<sup>®</sup> Dining, de eerste eetkamerstoelen van Stressless<sup>®</sup>. Het beproefde BalanceAdapt™systeem en een traploos glijsysteem brengen het populaire Stressless<sup>®</sup> comfort ook naar uw eettafel. Maak het comfortabel voor uzelf en geniet in perfecte balans van uw maaltijden met Stressless<sup>®</sup> Dining. <br>Laat u inspireren door de actuele Stressless<sup>®</sup> catalogus en kom meer te weten over de huidige acties.',
    },
    rowten: {
        headline: 'Nu testen: Stressless @home',
        introtext: 'Ontdek nu het Stressless<sup>®</sup> assortiment praktisch in de app. U kunt alle modellen naar wens configureren en dankzij de augmented reality-configurator uw nieuwe Stressless<sup>®</sup> meubel virtueel in uw huis bekijken. U kunt de app gratis krijgen in de Apple App Store en de Google Play Store.',
        appbuttonapple: 'App Store',
        applinkapple: 'https://itunes.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1&mt=8',
        appbuttongoogle: 'Google Play',
        applinkgoogle: 'https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers',
    },
    rownine: {
        headline: 'Het Stressless<sup>®</sup> assortiment',
        imagetext1: 'Catalogus',
        imagelink1: 'https://www.stressless.com/nl-nl/service/catalogus?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagetext2: 'Promoties',
        imagelink2: 'https://www.stressless.com/nl-nl/promotie?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagetext3: 'Registratie van garantie',
        imagelink3: 'https://www.stressless.com/nl-nl/service/garantie?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
}
