import { VideoCardModel } from './VideoCardModel';

export class HTML5VideoCardModel extends VideoCardModel {
    sources;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            text = '',
            uri = '',
            attributes = '',
            sources = {},
        } = {}
    ) {
        super({ display, cssClasses, text, uri, attributes });

        this.setSources(sources);
    }

    setSources(sources) {
        this.sources = sources;

        return this;
    }

    getSources() {
        return this.sources;
    }

    set sources(sources) {
        this.setSources(sources);
    }

    get sources() {
        return this.getSources();
    }
}