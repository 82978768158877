<template>
    <b-button
        variant="primary"
        :href="item.uri"
        :class="Array.from(item.cssClasses).join(' ')"
        squared
    >
        {{ item.text }}
    </b-button>
</template>

<script>
    import { SimpleButtonModel } from "../../models/SimpleButtonModel";
    import { BButton } from 'bootstrap-vue'

    export default {
        name: "SimpleButton",

        components: {
            BButton,
        },

        props: {
            item: {
                type: SimpleButtonModel,
                required: true,
            }
        }
    }
</script>

<style scoped>

</style>
