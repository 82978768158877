<template>
    <p
       :class="Array.from(item.cssClasses).join(' ')"
       v-html="item.text"
    ></p>
</template>

<script>
    import { SimpleTextModel } from '../../models/SimpleTextModel';

    export default {
        name: 'SimpleText',

        props: {
            item: {
                type: SimpleTextModel,
                required: true,
            }
        }
    }
</script>
