import { CssClassesBasedModel } from './CssClassesBasedModel';

export class ColumnModel extends CssClassesBasedModel {
    attributes;
    items;

    constructor(
        {
            display = true,
            cssClasses = new Set(),
            attributes = {},
            items = new Set(),
        } = {}
    ) {
        super({ display, cssClasses });

        this.setAttributes(attributes)
            .setItems(items);
    }

    setAttributes(attributes) {
        this.attributes = attributes;

        return this;
    }

    getAttributes() {
        return this.attributes;
    }

    set attributes(attributes) {
        this.setAttributes(attributes);
    }

    get attributes() {
        return this.getAttributes();
    }

    setItems(items) {
        this.items = items;

        return this;
    }

    getItems() {
        return this.items;
    }

    set items(items) {
        this.setItems(items);
    }

    get items() {
        return this.getItems();
    }
}