<template>
    <a v-if="item.link" :href="item.link">
        <b-card
            :title="item.text"
            :img-src="item.uri"
            :img-alt="item.text"
            :class="Array.from(item.cssClasses).join(' ')"
            img-top
            tag="article"
        />
    </a>

    <b-card
            v-else
            :title="item.text"
            :img-src="item.uri"
            :img-alt="item.text"
            :class="Array.from(item.cssClasses).join(' ')"
            img-top
            tag="article"
    />
</template>

<script>
    import { BCard } from 'bootstrap-vue';
    import { ImageCardModel } from '../../models/ImageCardModel';

    export default {
        name: 'ImageCard',

        components: {
            BCard,
        },

        props: {
            item: {
                type: ImageCardModel,
                required: true,
            }
        },
    }
</script>
