<template>
    <h1
        :class="Array.from(item.cssClasses).join(' ')"
        v-html="item.text"
    ></h1>
</template>

<script>
    import { HeadlineModel } from '../../../models/HeadlineModel';

    export default {
        name: 'H1',

        props: {
            item: {
                type: HeadlineModel,
                required: true,
            }
        },
    }
</script>
