export default {
    confirm: {
        external: 'Sie verlassen jetzt die Website.',
        externalApple: 'Vous serez redirigé vers l\'App Store d\'Apple.',
        externalGoogle: 'Vous serez redirigé vers le Google Play Store.',
        internal: 'Vous allez maintenant être redirigé vers www.stressless.com.',
    },
    base: {
        mainheadline: 'Stressless<sup>®</sup> - 50 ans de confort parfait',
    },
    realx: {
        headline: 'Stressless<sup>®</sup> – Sièges avec fonction de relaxation',
        introtext1: 'Nous vivons dans un monde de plus en plus stressant et devons veiller, chacun d\'entre nous, à nous réserver suffisamment de moments précieux pour les aspects vraiment essentiels de la vie. Depuis 50 ans, Stressless<sup>®</sup> aide des personnes dans le monde entier à bénéficier de plus de détente, d\'un plus grand confort et d\'un meilleur équilibre de vie grâce à des fonctionnalités intelligentes et polyvalentes.',
        introtext2: 'Découvrez les fauteuils de relaxation, canapés et chaises de salle à manger polyvalents de Stressless<sup>®</sup> et venez vous convaincre par vous-même du confort plébiscité de Stressless.<sup>®</sup>',
    },
    function: {
        headline: 'Des fonctions pour votre confort',
        introtext1: 'La caractéristique des meubles Stressless,<sup>®</sup> c’est le système Plus<sup>™</sup> intégré. Vous pouvez ainsi vous renverser en toute détente et trouver votre position assise idéale. Certains modèles offrent en outre avec le système BalanceAdapt<sup>™</sup> une fonction de balancement doux. Sur une sélection de modèles de fauteuils et de canapés, comme le canapé Mary, un repose-pieds électrique extensible (Power<sup>™</sup>) est disponible en option pour encore plus de confort.',
    },
    furniture: {
        headline: 'Trouvez votre meuble Stressless<sup>®</sup>',
        introtext1: 'Testez le confort d\'assise incomparable des sièges Stressless<sup>®</sup> Nous sommes votre interlocuteur pour la gamme Stressless<sup>®</sup> et vous conseillons de manière exhaustive sur les différentes versions et tailles, les promotions en cours,  ainsi que les combinaisons de couleurs et de matériaux afin que vous puissiez trouver votre meuble Stressless<sup>®</sup> idéal. N’hésitez pas à nous contacter afin que nous convenions d’un rendez-vous individuel.',
    },
    sesselsofa: {
        headline: 'Fauteuils et canapés: Confort dans le salon',
        introtext1: 'Chez Stressless,<sup>®</sup> le confort a de multiples visages: Fauteuils de relaxation et canapés sont disponibles dans de nombreux modèles, couleurs et versions. Vous trouverez aussi bien dans notre gamme des modèles classiques à piétement bois que des piétements acier modernes. Choisissez votre revêtement préféré dans une large gamme de couleurs de cuir et de tissu pour personnaliser votre meuble Stressless<sup>®</sup> en fonction de vos préférences.',
        introtext2: 'Laissez-vous inspirer par le tout dernier <a href="http://stressless.ekornes.com/DE/stresslessde/#/" target="_blank">catalogue Stressless<sup>®</sup></a> ou découvrez nos <a href="https://stressless.ekornes.com/DE/promode/" target="_blank">offres actuelles</a>.',
    },
    support: {
        headline: 'Un soutien parfait – de la tête jusqu’aux orteils',
        introtext1: 'Stressless<sup>®</sup> Power, une fonction de motorisation innovante qui vous offre encore plus de luxe, est disponible à la fois pour les fauteuils de relaxation et pour les canapés Stressless.<sup>®</sup> En appuyant sur les touches du capteur, vous pouvez régler aussi bien l\'inclinaison du repose-pieds que celle du dossier individuellement. Cette combinaison offre un soutien optimal à votre corps pour un confort inégalable.',
    },
    homeoffice: {
        headline: 'Le confort en télétravail',
        introtext1: 'Que ce soit à la maison ou au bureau, un poste de travail bien aménagé favorise la productivité. Pas besoin non plus de renoncer au confort Stressless pendant vos heures de travail. De nombreux modèles de fauteuils dotés du piètement Home Office sont disponibles. Découvrez par ailleurs les modèles Home Office Stressless au design épuré, qui sont également équipés d\'une fonction relaxation et assurent ainsi votre confort personnel durant les heures de travail.',
    },
    samscott: {
        headline: 'Chauffage et massage: Stressless<sup>®</sup> Sam und Scott',
        introtext: 'Sur ces deux modèles de fauteuils <a href="https://www.youtube-nocookie.com/embed/viaks7Z7E0M" target="_blank">Sam</a> et <a href="https://www.youtube-nocookie.com/embed/xzDwNlreAlc" target="_blank">Scott</a>, le repose-pieds, le dossier et l\'appuie-tête peuvent être aisément réglés séparément et intégralement en continu par télécommande. Ces deux fauteuils peuvent être équipés au choix d\'une fonction de chauffage intégrée et d\'une fonction optionnelle de massage léger. Testez cette nouvelle expérience d\'assise et de couchage dans notre exposition.',
    },
    table: {
        headline: 'Stressless<sup>®</sup> vous invite à table',
        introtext: 'Essayez <a href="https://www.youtube-nocookie.com/embed/adM9-7p3Edw" target="_blank">Stressless<sup>®</sup> Dining</a>, les premières chaises de salle à manger de Stressless<sup>®</sup>. Le système BalanceAdapt™ éprouvé et un système de coulissement en continu apportent désormais à votre table à mange le confort plébiscité de Stressless.<sup>®</sup> Installez-vous confortablement et profitez de vos repas en parfait équilibre avec Stressless<sup>®</sup> Dining.',
    },
    favoriteplace: {
        headline: 'En 8 jours ouvrés seulement dans votre nouveau coin préféré.',
        introtext: 'Certaines versions sélectionnées des modèles Stressless<sup>®</sup> les plus plébiscités sont désormais disponibles en quelques jours seulement. Vous pouvez ainsi savourer le confort Stressless<sup>®</sup> chez vous en quelques jours ouvrés (des délais de livraison différents sont possibles pendant les congés annuels). Sachez-en plus sur le <a href="https://www.stressless.com/fr-ch/promotions/lieferprogramm" target="_blank">programme de livraison rapide Stressless<sup>®</sup></a> ou venez nous voir – nous nous ferons un plaisir de vous conseiller.',
    },
    home: {
        headline: 'Faire un essai maintenant: <br>Stressless @home',
        introtext: 'Découvrez maintenant la gamme Stressless<sup>®</sup> de façon pratique via une application. Vous pouvez ainsi configurer tous les modèles comme vous le souhaitez et, grâce au configurateur à réalité augmentée, visualiser dès à présent virtuellement votre nouveau meuble Stressless<sup>®</sup> dans votre intérieur. Téléchargez cette application gratuitement <a href="https://apps.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1" target="_blank">Apple App Store</a> ainsi que dans le <a href="https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers" target="_blank">Google Play Store</a>.',
    },
    warranty: {
        headline: 'La garantie Stressless<sup>®</sup>',
        introtext: 'Après l\'achat d\'un meuble Stressless<sup>®</sup> neuf, assurez-vous de bénéficier de notre garantie de 10 ans sur le mécanisme interne ou encore de 5 ans sur les pièces électriques. Enregistrez pour cela votre meuble Stressless<sup>®</sup> sur stressless.com dans les 6 mois suivant l\'achat. Contactez-nous pour plus de détails ou retrouvez davantage d\'informations concernant la garantie sur stressless.com.',
        image: 'assets/img/36751_2102261046/Garantie/Garantie_FR.jpg',
    },
    handmade: {
        headline: '50 ans d’artisanat d’art venu de Norvège',
        introtext: 'Le berceau de Stressless se trouve dans une petite ville de la côte ouest de la Norvège. Inspiré par le paysage pittoresque, l\'entrepreneur Jens E. Ekornes a eu l\'idée de créer un confort d\'assise optimal et un lieu de véritable détente. Le fauteuil de relaxation Stressless<sup>®</sup> qu’il a créé à l’époque célèbre aujourd’hui son 50e anniversaire. Ce fauteuil symbolise l\'interaction parfaite entre tradition scandinave, savoir-faire artisanal, innovation technologique et passion des meubles.',
    },
    nature: {
        headline: 'Stressless<sup>®</sup> – en harmonie avec la Nature',
        introtext: 'Outre le confort inégalé, la production durable et écologique de l\'usine Stressless<sup>®</sup> norvégienne représente un objectif essentiel. Les meubles confort Stressless<sup>®</sup> sont ainsi fabriqués individuellement selon vos souhaits, en utilisant uniquement des matériaux de premier choix et les meilleures qualités de cuirs et de tissus, tout cela dans le respect de critères environnementaux rigoureux.',
    },

    rowone: {
        image:'assets/img/34836_2008061551/Entspannung543x300.jpg',
        headline: 'Le temps de la détente.',
        introtext: 'Découvrez la détente absolue: La marque norvégienne Stressless<sup>®</sup> offre un confort d’assise maximal grâce à de multiples fonctionnalités intelligentes dans un design scandinave.',
    },
    rowtwo: {
        headline: 'Des fonctions pour votre confort',
        introtext1: 'Le système intégré Plus™ est la marque de fabrique des meubles Stressless<sup>®</sup>. Vous pouvez ainsi vous renverser en toute détente et trouver votre position assise idéale. Certains modèles offrent en outre une fonction de balancement doux avec le système BalanceAdapt™. Sur une sélection de modèles de fauteuils et de canapés, vous disposez avec le système Power™ optionnel d’un repose-pieds déployable électriquement pour vous offrir encore davantage de confort.',
        introtext2: 'Découvrez les fauteuils de relaxation, canapés et chaises de salle à manger polyvalents de Stressless<sup>®</sup> et laissez-vous convaincre personnellement par le fameux confort Stressless®.',
    },
    rowthree: {
        mediatext1: 'Plus™ System',
        medialink1: 'https://www.youtube-nocookie.com/embed/DJYFDs3tEwA',
        mediatext2: 'BalanceAdapt™',
        medialink2: 'https://www.youtube-nocookie.com/embed/q-1mUk3SPTw',
        mediatext3: 'Power™',
        medialink3: 'https://www.youtube-nocookie.com/embed/-Ew88Z7pj0M',
    },
    rowfour: {
        headline: 'En 8 jours ouvrés seulement dans votre nouveau coin préféré.',
        introtext: 'Des versions sélectionnées des modèles Stressless<sup>®</sup> les plus populaires sont maintenant disponibles en quelques jours seulement. Vous pourrez ainsi profiter du confort Stressless<sup>®</sup> dans votre intérieur en seulement huit jours ouvrés (les délais de livraison peuvent varier en période de congés annuels). Apprenez-en davantage sur Stressless<sup>®</sup> ou venez nous voir – nous nous ferons un plaisir de vous conseiller.',
        buttontext: 'En savoir plus',
        buttonlink: 'https://www.stressless.com/fr-ch/promotions/lieferprogramm?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
    rowfive: {
        image: 'assets/img/hero.jpg',
        headline: 'Trouvez votre meuble Stressless<sup>®</sup>',
        introtext: 'Testez le confort d’assise incomparable de Stressless<sup>®</sup>. Nous sommes votre interlocuteur pour tout ce qui concerne la gamme Stressless<sup>®</sup> et vous apportons un conseil complet sur les différentes versions et tailles, les promotions en cours ainsi que les combinaisons de couleurs et de matériaux afin que vous puissiez trouver votre meuble Stressless<sup>®</sup> idéal. N’hésitez pas à nous contacter afin que nous convenions d’un rendez-vous individuel.',
    },
    rowsix: {
        headline: 'Fauteuils et canapés: Confort dans le salon',
        introtext1: 'Chez Stressless<sup>®</sup>, le confort prend de multiples formes: Fauteuils de relaxation et canapés sont disponibles dans de nombreux modèles, couleurs et versions. Vous trouverez aussi bien dans notre gamme des modèles classiques à piétement bois que des piétements acier modernes. Que vous préfériez les revêtements cuir ou tissu, choisissez votre revêtement favori parmi un vaste éventail de coloris et de matériaux.',
        introtext2: 'Configurez votre nouveau fauteuil ou votre nouveau canapé exactement comme vous le souhaitez et savourez le confort Stressless<sup>®</sup>.',
    },
    rowseven: {
        headline: 'Fonctionnalités de confort élargies: Stressless<sup>®</sup> Sam et Scott',
        introtext: 'Le repose-pieds, le dossier et l\'appuie-tête de ces deux modèles de fauteuils se règlent facilement de manière séparée et entièrement en continu par télécommande. Ces deux fauteuils peuvent être équipés en option d\'une fonction de chauffage intégrée ou d\'une fonction de chauffage combinée à un massage en douceur. Venez tester prochainement cette nouvelle expérience d\'assise et de couchage dans notre exposition.',
    },
    roweight: {
        headline: 'Stressless<sup>®</sup> vous convie à table',
        introtext: 'Essayez Stressless<sup>®</sup> Dining les premières chaises de salle à manger de Stressless<sup>®</sup>. Le système éprouvé BalanceAdapt™ ainsi qu’un système de glissière en continu apportent désormais aussi à votre table à manger le fameux confort Stressless<sup>®</sup>. Mettez-vous à l’aise et savourez vos repas avec Stressless<sup>®</sup> Dining dans un parfait équilibre. <br>Laissez-vous inspirer parle catalogue Stressless<sup>®</sup> actuel et obtenez plus d’informations sur les promotions en cours.',
    },
    rowten: {
        headline: 'Faire un essai maintenant : Stressless @home',
        introtext: 'Découvrez maintenant la gamme Stressless<sup>®</sup> confortablement via une appli. Vous pouvez ainsi configurer tous les modèles comme vous le souhaitez et, grâce au configurateur à réalité augmentée, voir déjà votre nouveau meuble Stressless<sup>®</sup> en virtuel chez vous. Vous obtenez gratuitement l’appli dans Apple App Store ainsi que dans Google Play Store.',
        appbuttonapple: 'App Store',
        applinkapple: 'https://itunes.apple.com/us/app/stressless-home/id1441966692?l=fr&ls=1&mt=8',
        appbuttongoogle: 'Google Play',
        applinkgoogle: 'https://play.google.com/store/apps/details?id=com.innersense.osmose.android.stresslessconsumers',
    },
    rownine: {
        headline: 'La gamme Stressless<sup>®</sup>',
        imagetext1: 'Catalogue',
        imagelink1: 'http://stressless.ekornes.com/DE/stresslesschfr/#/',
        imagetext2: 'Promotions',
        imagelink2: 'https://www.stressless.com/fr-ch/promotions?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
        imagetext3: 'Enregistrement de garantie',
        imagelink3: 'https://www.stressless.com/fr-ch/services/warrenty?utm_source=iframemobil&utm_medium=referral&utm_campaign=handel&utm_term=0&utm_content=0',
    },
}
